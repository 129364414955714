exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-centro-de-ayuda-js": () => import("./../../../src/pages/centro-de-ayuda.js" /* webpackChunkName: "component---src-pages-centro-de-ayuda-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-devolucion-js": () => import("./../../../src/pages/legal/politica-de-devolucion.js" /* webpackChunkName: "component---src-pages-legal-politica-de-devolucion-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terminos-y-condiciones-js": () => import("./../../../src/pages/legal/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-js" */),
  "component---src-pages-productos-absorbentes-js": () => import("./../../../src/pages/productos/absorbentes.js" /* webpackChunkName: "component---src-pages-productos-absorbentes-js" */),
  "component---src-pages-productos-dispensadores-js": () => import("./../../../src/pages/productos/dispensadores.js" /* webpackChunkName: "component---src-pages-productos-dispensadores-js" */),
  "component---src-pages-productos-higiene-de-manos-js": () => import("./../../../src/pages/productos/higiene-de-manos.js" /* webpackChunkName: "component---src-pages-productos-higiene-de-manos-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-productos-lavanderia-js": () => import("./../../../src/pages/productos/lavanderia.js" /* webpackChunkName: "component---src-pages-productos-lavanderia-js" */),
  "component---src-pages-productos-linea-profesional-js": () => import("./../../../src/pages/productos/linea-profesional.js" /* webpackChunkName: "component---src-pages-productos-linea-profesional-js" */),
  "component---src-pages-productos-quimicos-js": () => import("./../../../src/pages/productos/quimicos.js" /* webpackChunkName: "component---src-pages-productos-quimicos-js" */),
  "component---src-pages-productos-seguridad-ocupacional-js": () => import("./../../../src/pages/productos/seguridad-ocupacional.js" /* webpackChunkName: "component---src-pages-productos-seguridad-ocupacional-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-responsabilidad-social-js": () => import("./../../../src/pages/responsabilidad-social.js" /* webpackChunkName: "component---src-pages-responsabilidad-social-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-soluciones-js": () => import("./../../../src/pages/soluciones.js" /* webpackChunkName: "component---src-pages-soluciones-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../../../src/pages/trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

